<template>
  <div id="master-data-role-add" data-app>
    
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            Tambah Data
          </h3>
        </div>
      </div>

      <!--begin::Form-->
        <div class="card-body">
          <ValidationObserver ref="formAdd">
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Kode</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Kode" rules="required|min_value:1|max_value:4000000000" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-role-1" 
                            label-for="input-role-1"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <vue-number-input 
                              :attrs="{ id: `input-role-1`, class: `b-form-spinbutton form-control form-control-solid d-flex align-items-stretch` }"
                              v-model="form.role"
                              :min="0"
                              size="large"
                              :max="4000000000"
                              required
                              center
                              controls
                            ></vue-number-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <!--begin::Form Group-->
            <div class="form-group row">
                <label class="col-xl-3 col-lg-3 col-form-label">Nama Role</label>
                <div class="col-lg-9 col-xl-7">
                    <validationProvider name="Nama Role" rules="required|min:2|max:50" v-slot="{ valid, errors }">
                        <b-form-group
                            id="input-group-role-2" 
                            label-for="input-role-2"
                            :invalid-feedback="errors[0]"
                            class="mb-0"
                            :state="valid">
                            <b-form-input
                              id="input-role-2"
                              v-model="form.name"
                              class="form-control form-control-solid"
                              autocomplete="off"
                              required
                              size="lg"
                              placeholder="Nama Role"
                              :state="valid"
                              ></b-form-input>
                        </b-form-group>
                    </validationProvider>
                </div>
            </div>

            <div class="table-responsive pt-10">
              <table class="table b-table table-borderless">
                <thead class="thead-light">
                  <tr>
                    <th class="font-weight-bold text-left " v-for="heading in ['Module', 'Read', 'Create', 'Update', 'Delete']" :key="heading">{{ heading }}</th>
                  </tr>
                </thead>

                <tr v-for="(val, name) in form.permissions" :key="name">
                  <td class="">{{ name | title }}</td>
                  <td class="">
                    <b-form-checkbox size="lg" v-model="val['view']" ></b-form-checkbox>
                  </td>
                  <td class="">
                    <b-form-checkbox size="lg" v-if="!listNotShow.includes(name)" v-model="val['create']" ></b-form-checkbox>
                  </td>
                  <td class="">
                    <b-form-checkbox size="lg" v-if="!listNotShow.includes(name)" v-model="val['edit']" ></b-form-checkbox>
                  </td>
                  <td class="">
                    <b-form-checkbox size="lg" v-if="!listNotShow.includes(name)" v-model="val['delete']" ></b-form-checkbox>
                  </td>
                </tr>
              </table>
            </div>

          </b-form>
          </ValidationObserver>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button 
                @click="onSubmit" 
                ref="kt_submit"
                class="btn btn-primary font-weight-bold"
              >
                <i class="la la-save"></i>
                Simpan
              </button>
              &nbsp;
              <button
                @click="onReset"
                class="btn btn-clean font-weight-bold"
              >
                <i class="la la-recycle"></i>
                Reset
              </button>
            </div>
          </div>
        </div>
      <!--end::Form-->
    </div>

    <v-dialog
    v-model="dialogEnd"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Info</v-card-title>

        <v-card-text>
          Ingin tambah data lagi?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="$router.push({ name: 'master-data-role-list' })">
            TIDAK
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onStayPage">
            YA
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RoleService from "@/core/services/api/master/role.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-role-add",
  data() {
    return {
      form: {
        role: 0,
        name: null,
        permissions: {
            'full_code': {
                'view': false,
            },
            'profil': {
                'view': true,
            },
            'cutting_he': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'afkir_dini': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'verifikasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'statistik': {
                'view': false,
            },
            'perusahaan': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'hatchery': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak_farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'wilayah': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            }, 
            'surat_edaran': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'role': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'user': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_populasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_produksi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_distribusi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'komersil_populasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'komersil_distribusi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
        },
      },
      listNotShow: ['full_code', 'profil', 'statistik'],
      dialogEnd: false,
      show: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    onStayPage() {
      this.dialogEnd = false
      this.onReset()
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$refs.formAdd.validate().then(success => {
        if (!success) {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Silahkan lengkapi form data terlebih dahulu',
            })
            return;
        }

        // set spinner to submit button
        const submitButton = this.$refs["kt_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        // setTimeout(() => {
            RoleService.addRole(this.form)
                .then((res) => {
                    this.removeSpinner(submitButton)
                    ErrorService.message(res.data)
                    
                    if (res.data.status) {
                      this.dialogEnd = true
                    }
                    return;
                })
                .catch((err) => {
                  this.removeSpinner(submitButton)
                  ErrorService.status(err)
                })

        // }, 1000);
      })
    },
    onReset() {
      // Reset our form values
      
        this.form.role= 1
        this.form.name= null
        this.form.permissions= {
            'full_code': {
                'view': false,
            },
            'profil': {
                'view': true,
            },
            'cutting_he': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'afkir_dini': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'verifikasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'statistik': {
                'view': false,
            },
            'perusahaan': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'hatchery': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'peternak_farm': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'wilayah': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            }, 
            'surat_edaran': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'role': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'user': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_populasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_produksi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'nasional_distribusi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'komersil_populasi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
            'komersil_distribusi': {
                'view': false,
                'create': false,
                'edit': false,
                'delete': false,
            },
        }
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Role" }]);
    if (!this.currentUser.menu.role) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
  },
};
</script>